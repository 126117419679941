.wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.container {
    width: var(--page-width);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
