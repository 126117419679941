.wrapper {
    width: 100%;
    margin-top: 10px 0;
    display: flex;
    gap: var(--formal-gap);
    flex-direction: column;

    .title {
        font-size: var(--title-size);
    }
}

.end-line {
    &:after {
        content: '';
        height: 0.5em;
        margin: 50px 0 18px;
        border-top: 2px dashed var(--text-color);
    }
}

// @media (max-width: 1024px) {
//     .container {
//         flex-direction: column;
//         align-items: center;
//     }
// }
