.wrapper {
    height: 280px;
    width: 100%;
    display: flex;
    margin-bottom: 30px;
    justify-content: center;
    align-items: center;
    background-color: var(--background-color);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
        rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
    font-size: 4.8rem;
}

@media (max-width: 1024px) {
    .wrapper {
        height: 100px;
        font-size: var(--title-size);
    }
}
