.wrapper {
    position: relative;
}

.container {
    height: 80px;
    width: 100%;
    display: flex;
    gap: var(--formal-gap);
    flex-direction: column;
    margin: 8px;
    border-radius: 5px;
    padding: 10px 30px;
    background-color: var(--background-color);
    color: var(--text-color);
    opacity: 0.7;
    transition: ease-in-out 0.2s;
    overflow: hidden;

    .project-info {
        height: fit-content;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .project-name {
            font-size: var(--title-size);
        }

        .project-link {
            display: flex;
            gap: 8px;
            z-index: 10;
            overflow-x: auto;
        }
    }

    .project-descript {
        display: none;
        text-align: justify;
        white-space: pre-line;
    }

    &:hover {
        height: 180px;
        opacity: 1;
        overflow-y: overlay;
        float: left;

        .project-descript {
            display: block;
        }
    }
}

@media (max-width: 1024px) {
    .container {
        height: fit-content;
        .project-info {
            flex-direction: column;

            .project-name {
                text-align: center;
            }
        }

        &:hover {
            height: 95%;
        }
    }
}
