.wrapper {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 100px;
    padding: 9px 16px;
    border-radius: 4px;
    font-size: 1.6rem;
    font-weight: 700;
    font-family: var(--font-family);
    cursor: pointer;
    background-color: var(--white);
    border: 1px solid transparent;
    user-select: none;

    &.disabled {
        pointer-events: none;
        opacity: 0.5;
    }
}

.icon + .title,
.title + .icon {
    margin-left: 8px;
}

.icon {
    display: inline-block;
    width: 24px;
    text-align: center;
}

// Button types

.primary {
    color: var(--text-color);
    background-color: var(--btn-color);
    border-color: var(--btn-color);

    &:hover {
        border-color: var(--btn-color);
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0.06)), var(--primary);
    }
}

.textOnly {
    &:hover {
        text-decoration: underline;
    }
}

// Button sizes
.small {
    min-width: 88px;
    padding: 4px 16px;
}

.large {
    padding: 14px 16px;
    min-width: 140px;
}
