.wrapper {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.title {
    font-size: var(--title-size);
    margin-bottom: 30px;
}

.items {
    width: var(--page-width);
    display: flex;
    gap: var(--formal-gap);
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
