.wrapper {
    width: var(--page-width);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title {
    font-size: var(--title-size);
    margin-bottom: 30px;
    align-self: flex-start;
}

.each-slide-effect {
    > div {
        height: 600px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        border-radius: 5px;
        background-size: cover;
    }
}

.slide-btn {
    cursor: pointer;
    height: 10%;
    width: 10%;
    text-align: center;
    background-color: transparent;
    color: var(--text-color);
    font-size: 2.8rem;

    &:active {
        background: #666;
    }
}

@media (max-width: 1024px) {
    .each-slide-effect {
        > div {
            height: 100vw;
        }
    }
}
