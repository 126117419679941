.wrapper {
    position: fixed;
    height: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--background-color);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    font-size: 1.8rem;
    opacity: 0;
    z-index: 11;
    transition: linear 0.1s;
}

.show {
    height: 100px;
    opacity: 1;
}
