// Container
.container {
    display: flex;
    gap: var(--formal-gap);
    margin-top: 30px;

    .logo {
        width: 150px;
        height: 150px;
        border-radius: 5px;
        object-fit: cover;
    }

    .content {
        flex: 1;
        width: 100%;
        height: 120px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }
}

.content {
    .time {
        font-size: var(--title-size);
        color: var(--primary);
    }

    .name {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        padding-right: 10px;
        font-size: 1.8rem;
    }

    .experience {
        position: relative;
        // width: 100%;
        height: 10px;
        border-radius: 999px;
        background-color: var(--text-color);

        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: var(--skill-percent);
            height: 10px;
            border-radius: 999px;
            background-color: var(--btn-color);
        }
    }
}

@media (max-width: 1024px) {
    .container {
        flex-direction: column;
        align-items: center;
    }
}
