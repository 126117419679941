.wrapper {
    display: flex;
    align-items: center;
    gap: 18px;
    cursor: pointer;

    .main-info {
        font-size: 1.8rem;
        color: var(--primary);
    }

    .bonus-info {
        font-weight: 400;
    }
}

@media (max-width: 1024px) {
    .wrapper {
        .main-info {
            font-size: 1.3rem;
        }
    }

    svg {
        font-size: 1.8rem !important;
    }
}
