.wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.content {
    display: flex;
    gap: 60px;
    justify-content: center;

    .info {
        display: flex;
        gap: var(--formal-gap);
        flex-direction: column;
    }

    .input {
        display: flex;
        gap: var(--formal-gap);
        flex-direction: column;
    }
}

.input {
    input,
    textarea {
        padding: 15px 18px;
        border-radius: 5px;
        border: 1px solid transparent;
        font-family: var(--font-family);
        resize: none;

        &:focus {
            border: 1px solid var(--primary);
        }
    }
}

.submit {
    align-self: flex-end;
}

@media (max-width: 1024px) {
    .content {
        width: 80%;
        flex-direction: column;
    }

    .submit {
        width: 100%;
    }
}
