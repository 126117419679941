@import 'normalize.css';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,400&display=swap');

:root {
    // color
    --primary: #609966;
    --text-color: #40513b;
    --background-color: #edf1d6;
    --btn-color: #9dc08b;

    // font
    --font-family: 'Montserrat';
    --title-size: 3.6rem;
    --font-size: 1.6rem;

    // gap
    --formal-gap: 30px;

    //size
    --page-width: 810px;
    --layout-width: 100vw;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
}

body {
    font-family: var(--font-family);
    font-size: var(--font-size);
    line-height: 1.5;
    text-rendering: optimizespeed;
    color: var(--text-color);
    background-color: var(--background-color);
    overflow-x: hidden;
    overflow-y: overlay;
}

// Scrollbar CSS
html *::-webkit-scrollbar {
    border-radius: 0;
    width: 8px;
}

html *::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.15);
}

html *::-webkit-scrollbar-track {
    border-radius: 0;
    background-color: rgba(0, 0, 0, 0);
}

button,
input,
textarea,
[tabindex] {
    outline: none;
    border: none;
}

a {
    color: var(--text-color);
    text-decoration: none;
}

.react-slideshow-container {
    width: 600px;
}

// mobile responsive
@media (max-width: 1024px) {
    :root {
        // font
        --title-size: 2.8rem;
        --font-size: 1.1rem;
        // gap
        --formal-gap: 15px;
        //size
        --page-width: 80%;
        --layout-width: 100vw;
    }

    .react-slideshow-container {
        width: 100vw;
    }
}
