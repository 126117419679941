.wrapper {
    width: var(--page-width);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.hello,
.jobs {
    font-size: var(--title-size);
    font-weight: 700;

    span {
        color: var(--primary);
    }
}

.name {
    font-size: 4.8rem;
    font-weight: 700;
}
