.wrapper {
    width: var(--layout-width);
    display: flex;
    flex-direction: column;
    margin-bottom: 100px;
}

.content {
    height: calc(100vh - 100px);
    width: 100%;
    min-height: 500px;
    flex: 1;
    display: flex;
    justify-content: center;
    margin: 100px 0 80px;
}
