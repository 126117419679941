.wrapper {
    position: relative;
    width: 300px;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 20px;
    border-radius: 5px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    text-align: justify;
    white-space: pre-line;
    transition: 0.5s ease-out;
    cursor: pointer;

    .logo {
        width: 120px;
        height: 120px;
        transition: 0.2s ease-out;
    }

    .title {
        font-size: 2.4rem;
        transition: 0.2s ease-out;
    }

    .content {
        width: 100%;
        height: 0;
        display: -webkit-box;
        opacity: 0;
        font-size: 1.4rem;
        overflow: hidden;
        word-break: break-word; /* Breaks long words to prevent overflow */
        hyphens: auto;
        text-overflow: ellipsis;
        -webkit-line-clamp: 7;
        -webkit-box-orient: vertical;
        transition: 0.5s linear;
    }

    &:hover {
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

        .logo {
            width: 80px;
            height: 80px;
        }

        .title {
            font-size: 1.8rem;
        }

        .content {
            height: 100%;
            opacity: 1;
        }
    }
}

.content {
    span {
        color: var(--primary);
        text-decoration: underline;
    }
}

@media (max-width: 1024px) {
    .wrapper {
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

        .logo {
            width: 80px;
            height: 80px;
        }

        .title {
            font-size: 1.8rem;
        }

        .content {
            height: 100%;
            opacity: 1;
        }
    }
}
