// Container
.container {
    display: flex;
    gap: var(--formal-gap);
    margin-top: 30px;

    .logo {
        width: 150px;
        height: 150px;
        object-fit: contain;
    }

    .content {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
}

.content {
    .time {
        font-size: var(--title-size);
        color: var(--primary);
    }

    .name {
        font-size: 1.8rem;
    }

    .info {
        font-size: 1.4rem;
        font-weight: 500;
        text-align: justify;
        white-space: pre-line;

        span {
            text-decoration: underline;
            color: var(--primary);
        }
    }
}

@media (max-width: 1024px) {
    .container {
        flex-direction: column;
        align-items: center;
    }

    .content {
        .time {
        }
    }
}
