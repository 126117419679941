.wrapper {
    --about-height: 450px;

    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.container {
    width: var(--page-width);
    display: flex;
    gap: 30px;
    justify-content: center;
}

.img {
    width: 280px;
    height: var(--about-height);
    border-radius: 5px;
    background: #9dc08b;
    object-fit: cover;
    object-position: center;
}

// Content
.content {
    width: 50%;
    min-height: var(--about-height);
    flex: 1;
    display: flex;
    flex-direction: column;

    .title {
        margin-top: -12px;
        font-size: var(--title-size);
    }
}

.introduce {
    font-weight: 500;
    text-align: justify;
    white-space: pre-line;

    span {
        color: var(--primary);
        text-decoration: underline;
    }
}

// Infomation
.information {
    display: flex;
    justify-content: flex-start;
    gap: var(--formal-gap);
    margin: 30px 0;

    .info-title {
        display: flex;
        flex-direction: column;
        gap: 10px;
        font-weight: 600;
        color: var(--text-color);
        cursor: pointer;
    }

    .info-content {
        display: flex;
        flex-direction: column;
        gap: 10px;
        cursor: pointer;
    }
}

// Button
.button {
    display: flex;
    gap: 18px;
    justify-content: flex-end;
}

.download-btn {
    padding: 18px;
    border-radius: 5px;
    background-color: var(--btn-color);
    color: var(--text-color);
    font-family: var(--font-family);
    font-size: 1.8rem;
    font-weight: 700;
    transition: linear 0.1s;

    &:hover {
        background-color: var(--primary);
    }

    &:active {
        background-color: var(--btn-color);
    }
}

@media (max-width: 1024px) {
    .container {
        flex-direction: column;
        align-items: center;
    }

    .content {
        width: 100%;
    }

    .button {
        width: 100%;
        flex-direction: column;

        a {
            margin: 0;
        }
    }
}
