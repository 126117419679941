.link {
    padding: 15px;
    transition: linear 0.2s;
    font-weight: 600;

    &:hover {
        color: var(--primary);
    }
}

.active {
    color: var(--primary);

    .underline {
        width: 100%;
        height: 2px;
        background-color: var(--primary);
        animation: example 0.2s;
    }
}

@keyframes example {
    from {
        width: 0;
    }
    to {
        width: 100%;
    }
}
